import { navigate } from 'gatsby';
import { useEffect } from 'react';

interface UseRedirectionParams {
    featureFlag: boolean;
    navigationPath?: string;
}

const usePageRedirection = ({ featureFlag, navigationPath = '/' }: UseRedirectionParams) => {
    useEffect(() => {
        if (!featureFlag) {
            navigate(navigationPath);
        }
    }, [featureFlag]);

    return !featureFlag ? true : false;
};

export default usePageRedirection;
